::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.dark {
  ::-webkit-scrollbar-thumb {
    background-color: var(--black9);
  }
  ::-webkit-scrollbar-track {
    background: var(--black3);
  }
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-clip: content-box;
}

@supports (-moz-appearance:none) {
  :root {
    scrollbar-color: var(--black9) var(--black3);
    scrollbar-width: thin;
  }
}
